let resumeData = {
    "imagebaseurl": "https://rbhatia46.github.io/",
    "name": "Maharshi Mahesh Yogi",
    "role": "Frontend Developer and Data Scientist",
    "linkedinId": "Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks": [{
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/rahul-bhatia-67ba08121/",
            "className": "fa fa-linkedin"
        },
        {
            "name": "github",
            "url": "http://github.com/rbhatia46",
            "className": "fa fa-github"
        },
        {
            "name": "skype",
            "url": "http://twitter.com/rbhatia46",
            "className": "fa fa-twitter"
        }
    ],
    "aboutme": "I am currently a pre-final year student at The LNM Institute of Information Technology and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Machine Learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address": "India",
    "website": "https://rbhatia46.github.io",
    "education": [{
            "UniversityName": "The LNM Insitute of Information Technology",
            "specialization": "Some specialization",
            "MonthOfPassing": "Aug",
            "YearOfPassing": "2020",
            "Achievements": "Some Achievements"
        },
        {
            "UniversityName": "Some University",
            "specialization": "Some specialization",
            "MonthOfPassing": "Jan",
            "YearOfPassing": "2018",
            "Achievements": "Some Achievements"
        }
    ],
    "work": [{
            "CompanyName": "Some Company",
            "specialization": "Some specialization",
            "MonthOfLeaving": "Jan",
            "YearOfLeaving": "2018",
            "Achievements": "Some Achievements"
        },
        {
            "CompanyName": "Some Company",
            "specialization": "Some specialization",
            "MonthOfLeaving": "Jan",
            "YearOfLeaving": "2018",
            "Achievements": "Some Achievements"
        }
    ],
    "skillsDescription": "Your skills here",
    "skills": [{
            "skillname": "HTML5"
        },
        {
            "skillname": "CSS"
        },
        {
            "skillname": "Reactjs"
        }
    ],
    "portfolio": [{
            "name": "project1",
            "description": "mobileapp",
            "imgurl": "images/portfolio/phone.jpg"
        },
        {
            "name": "project2",
            "description": "mobileapp",
            "imgurl": "images/portfolio/project.jpg"
        },
        {
            "name": "project3",
            "description": "mobileapp",
            "imgurl": "images/portfolio/project2.png"
        },
        {
            "name": "project4",
            "description": "mobileapp",
            "imgurl": "images/portfolio/phone.jpg"
        }
    ],
    "testimonials": [{
            "description": "This is a sample testimonial",
            "name": "Some technical guy"
        },
        {
            "description": "This is a sample testimonial",
            "name": "Some technical guy"
        }
    ]
}

export default resumeData